/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import CurriculumSupportProgramStructureEntity from 'Models/Security/Acl/CurriculumSupportProgramStructureEntity';
import InstitutionAdminProgramStructureEntity from 'Models/Security/Acl/InstitutionAdminProgramStructureEntity';
import StudentAdvisorProgramStructureEntity from 'Models/Security/Acl/StudentAdvisorProgramStructureEntity';
import VisitorsProgramStructureEntity from 'Models/Security/Acl/VisitorsProgramStructureEntity';
import AdminProgramStructureEntity from 'Models/Security/Acl/AdminProgramStructureEntity';
import UserProgramStructureEntity from 'Models/Security/Acl/UserProgramStructureEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IProgramStructureEntityAttributes extends IModelAttributes {
	description: string;
	name: string;

	components: Array<
		| Models.RequirementEntity
		| Models.IRequirementEntityAttributes
	>;
	programVersionId: string;
	programVersion: Models.ProgramVersionEntity | Models.IProgramVersionEntityAttributes;
	rootComponents: Array<
		| Models.RequirementEntity
		| Models.IRequirementEntityAttributes
	>;
	studyPlans: Array<
		| Models.StudyPlanEntity
		| Models.IStudyPlanEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ProgramStructureEntity', 'Program Structure')
// % protected region % [Customise your entity metadata here] end
export default class ProgramStructureEntity extends Model
	implements IProgramStructureEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new CurriculumSupportProgramStructureEntity(),
		new InstitutionAdminProgramStructureEntity(),
		new StudentAdvisorProgramStructureEntity(),
		new VisitorsProgramStructureEntity(),
		new AdminProgramStructureEntity(),
		new UserProgramStructureEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Description'] off begin
	/**
	 * Program structure description. This is used as help text for students
	 */
	@observable
	@attribute<ProgramStructureEntity, string>()
	@CRUD({
		name: 'Description',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	/**
	 * Program structure name
	 */
	@Validators.Required()
	@observable
	@attribute<ProgramStructureEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	/**
	 * Root components of program structures. Only the root component can have this relationship with program structures
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Component'] off begin
		name: 'Components',
		displayType: 'reference-multicombobox',
		order: 30,
		referenceTypeFunc: () => Models.RequirementEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'components',
			oppositeEntity: () => Models.RequirementEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Component'] end
	})
	public components: Models.RequirementEntity[] = [];

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Program Version'] off begin
		name: 'Program Version',
		displayType: 'reference-combobox',
		order: 40,
		referenceTypeFunc: () => Models.ProgramVersionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Program Version'] end
	})
	public programVersionId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public programVersion: Models.ProgramVersionEntity;

	/**
	 * Requirements of program structures. All components should link to a program structure
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Root Component'] off begin
		name: 'Root Components',
		displayType: 'reference-multicombobox',
		order: 50,
		referenceTypeFunc: () => Models.RequirementEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'rootComponents',
			oppositeEntity: () => Models.RequirementEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Root Component'] end
	})
	public rootComponents: Models.RequirementEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Study Plan'] off begin
		name: 'Study Plans',
		displayType: 'reference-multicombobox',
		order: 60,
		referenceTypeFunc: () => Models.StudyPlanEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'studyPlans',
			oppositeEntity: () => Models.StudyPlanEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Study Plan'] end
	})
	public studyPlans: Models.StudyPlanEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IProgramStructureEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IProgramStructureEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.components !== undefined && Array.isArray(attributes.components)) {
				for (const model of attributes.components) {
					if (model instanceof Models.RequirementEntity) {
						this.components.push(model);
					} else {
						this.components.push(new Models.RequirementEntity(model));
					}
				}
			}
			if (attributes.programVersionId !== undefined) {
				this.programVersionId = attributes.programVersionId;
			}
			if (attributes.programVersion !== undefined) {
				if (attributes.programVersion === null) {
					this.programVersion = attributes.programVersion;
				} else if (attributes.programVersion instanceof Models.ProgramVersionEntity) {
					this.programVersion = attributes.programVersion;
					this.programVersionId = attributes.programVersion.id;
				} else {
					this.programVersion = new Models.ProgramVersionEntity(attributes.programVersion);
					this.programVersionId = this.programVersion.id;
				}
			}
			if (attributes.rootComponents !== undefined && Array.isArray(attributes.rootComponents)) {
				for (const model of attributes.rootComponents) {
					if (model instanceof Models.RequirementEntity) {
						this.rootComponents.push(model);
					} else {
						this.rootComponents.push(new Models.RequirementEntity(model));
					}
				}
			}
			if (attributes.studyPlans !== undefined && Array.isArray(attributes.studyPlans)) {
				for (const model of attributes.studyPlans) {
					if (model instanceof Models.StudyPlanEntity) {
						this.studyPlans.push(model);
					} else {
						this.studyPlans.push(new Models.StudyPlanEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		components {
			${Models.RequirementEntity.getAttributes().join('\n')}
		}
		programVersion {
			${Models.ProgramVersionEntity.getAttributes().join('\n')}
		}
		rootComponents {
			${Models.RequirementEntity.getAttributes().join('\n')}
		}
		studyPlans {
			${Models.StudyPlanEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			components: {},
			rootComponents: {},
			studyPlans: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'components',
							'studyPlans',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ProgramStructureEntity.prototype, 'created');
CRUD(modifiedAttr)(ProgramStructureEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
