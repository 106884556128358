/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import CurriculumSupportRequirementEntity from 'Models/Security/Acl/CurriculumSupportRequirementEntity';
import InstitutionAdminRequirementEntity from 'Models/Security/Acl/InstitutionAdminRequirementEntity';
import VisitorsRequirementEntity from 'Models/Security/Acl/VisitorsRequirementEntity';
import AdminRequirementEntity from 'Models/Security/Acl/AdminRequirementEntity';
import UserRequirementEntity from 'Models/Security/Acl/UserRequirementEntity';
import StudentAdvisorRequirementEntity from 'Models/Security/Acl/StudentAdvisorRequirementEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IRequirementEntityAttributes extends IModelAttributes {
	name: string;
	requirementType: Enums.requirementType;
	category: Enums.requirementCategory;
	displayName: string;
	condition: Enums.requirementCondition;
	order: number;
	description: string;
	displayOnSidebar: boolean;
	compulsory: boolean;
	showDiscontinuedCourses: boolean;

	programStructureId?: string;
	programStructure?: Models.ProgramStructureEntity | Models.IProgramStructureEntityAttributes;
	courseOfferings: Array<
		| Models.CourseOfferingEntity
		| Models.ICourseOfferingEntityAttributes
	>;
	parentRequirementId?: string;
	parentRequirement?: Models.RequirementEntity | Models.IRequirementEntityAttributes;
	requirements: Array<
		| Models.RequirementEntity
		| Models.IRequirementEntityAttributes
	>;
	rootProgramStructureId: string;
	rootProgramStructure: Models.ProgramStructureEntity | Models.IProgramStructureEntityAttributes;
	selectedCourses: Array<
		| Models.PlanSelectionEntity
		| Models.IPlanSelectionEntityAttributes
	>;
	requiringStudyPlans: Array<
		| Models.RequiringStudyPlanStudyPlanRequirement
		| Models.IRequiringStudyPlanStudyPlanRequirementAttributes
	>;
	unitConditions: Array<
		| Models.UnitConditionEntity
		| Models.IUnitConditionEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('RequirementEntity', 'Requirement')
// % protected region % [Customise your entity metadata here] end
export default class RequirementEntity extends Model
	implements IRequirementEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new CurriculumSupportRequirementEntity(),
		new InstitutionAdminRequirementEntity(),
		new VisitorsRequirementEntity(),
		new AdminRequirementEntity(),
		new UserRequirementEntity(),
		new StudentAdvisorRequirementEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@Validators.Required()
	@observable
	@attribute<RequirementEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Requirement Type'] off begin
	@Validators.Required()
	@observable
	@attribute<RequirementEntity, Enums.requirementType>()
	@CRUD({
		name: 'Requirement Type',
		displayType: 'enum-combobox',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.requirementTypeOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.requirementTypeOptions),
		displayFunction: (attr: Enums.requirementType) => Enums.requirementTypeOptions[attr],
	})
	public requirementType: Enums.requirementType;
	// % protected region % [Modify props to the crud options here for attribute 'Requirement Type'] end

	// % protected region % [Modify props to the crud options here for attribute 'Category'] off begin
	@Validators.Required()
	@observable
	@attribute<RequirementEntity, Enums.requirementCategory>()
	@CRUD({
		name: 'Category',
		displayType: 'enum-combobox',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.requirementCategoryOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.requirementCategoryOptions),
		displayFunction: (attr: Enums.requirementCategory) => Enums.requirementCategoryOptions[attr],
	})
	public category: Enums.requirementCategory;
	// % protected region % [Modify props to the crud options here for attribute 'Category'] end

	// % protected region % [Modify props to the crud options here for attribute 'Display Name'] off begin
	@Validators.Required()
	@observable
	@attribute<RequirementEntity, string>()
	@CRUD({
		name: 'Display Name',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public displayName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Display Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Condition'] off begin
	@Validators.Required()
	@observable
	@attribute<RequirementEntity, Enums.requirementCondition>()
	@CRUD({
		name: 'Condition',
		displayType: 'enum-combobox',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.requirementConditionOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.requirementConditionOptions),
		displayFunction: (attr: Enums.requirementCondition) => Enums.requirementConditionOptions[attr],
	})
	public condition: Enums.requirementCondition;
	// % protected region % [Modify props to the crud options here for attribute 'Condition'] end

	// % protected region % [Modify props to the crud options here for attribute 'Order'] on begin
	@Validators.Integer()
	@observable
	@attribute<RequirementEntity, number>()
	@CRUD({
		name: 'Order',
		displayType: 'hidden',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public order: number;
	// % protected region % [Modify props to the crud options here for attribute 'Order'] end

	// % protected region % [Modify props to the crud options here for attribute 'Description'] off begin
	/**
	 * Requirement description to display on study plan wizard
	 */
	@observable
	@attribute<RequirementEntity, string>()
	@CRUD({
		name: 'Description',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Display On Sidebar'] off begin
	@Validators.Required()
	@observable
	@attribute<RequirementEntity, boolean>()
	@CRUD({
		name: 'Display On Sidebar',
		displayType: 'checkbox',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public displayOnSidebar: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Display On Sidebar'] end

	// % protected region % [Modify props to the crud options here for attribute 'Compulsory'] off begin
	@Validators.Required()
	@observable
	@attribute<RequirementEntity, boolean>()
	@CRUD({
		name: 'Compulsory',
		displayType: 'checkbox',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public compulsory: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Compulsory'] end

	// % protected region % [Modify props to the crud options here for attribute 'Show Discontinued Courses'] off begin
	/**
	 * Allow RES admin to set if the courses in a requirement are shown on course selection page, even when they are not enabled
	 */
	@Validators.Required()
	@observable
	@attribute<RequirementEntity, boolean>()
	@CRUD({
		name: 'Show Discontinued Courses',
		displayType: 'checkbox',
		order: 100,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public showDiscontinuedCourses: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Show Discontinued Courses'] end

	/**
	 * Root components of program structures. Only the root component can have this relationship with program structures
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Program Structure'] off begin
		name: 'Program Structure',
		displayType: 'reference-combobox',
		order: 110,
		referenceTypeFunc: () => Models.ProgramStructureEntity,
		// % protected region % [Modify props to the crud options here for reference 'Program Structure'] end
	})
	public programStructureId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public programStructure: Models.ProgramStructureEntity;

	/**
	 * Offering available within this Version Requirement
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Course Offering'] off begin
		name: 'Course Offerings',
		displayType: 'reference-multicombobox',
		order: 120,
		referenceTypeFunc: () => Models.CourseOfferingEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'courseOfferings',
			oppositeEntity: () => Models.CourseOfferingEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Course Offering'] end
	})
	public courseOfferings: Models.CourseOfferingEntity[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Parent Requirement'] off begin
		name: 'Parent Requirement',
		displayType: 'reference-combobox',
		order: 130,
		referenceTypeFunc: () => Models.RequirementEntity,
		// % protected region % [Modify props to the crud options here for reference 'Parent Requirement'] end
	})
	public parentRequirementId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public parentRequirement: Models.RequirementEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Requirement'] off begin
		name: 'Requirements',
		displayType: 'reference-multicombobox',
		order: 140,
		referenceTypeFunc: () => Models.RequirementEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'requirements',
			oppositeEntity: () => Models.RequirementEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Requirement'] end
	})
	public requirements: Models.RequirementEntity[] = [];

	/**
	 * Requirements of program structures. All components should link to a program structure
	 */
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Root Program Structure'] off begin
		name: 'Root Program Structure',
		displayType: 'reference-combobox',
		order: 150,
		referenceTypeFunc: () => Models.ProgramStructureEntity,
		// % protected region % [Modify props to the crud options here for reference 'Root Program Structure'] end
	})
	public rootProgramStructureId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public rootProgramStructure: Models.ProgramStructureEntity;

	/**
	 * Courses that have been selected within a plan
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Selected Course'] off begin
		name: 'Selected Courses',
		displayType: 'reference-multicombobox',
		order: 160,
		referenceTypeFunc: () => Models.PlanSelectionEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'selectedCourses',
			oppositeEntity: () => Models.PlanSelectionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Selected Course'] end
	})
	public selectedCourses: Models.PlanSelectionEntity[] = [];

	/**
	 * Relationship signifying the Requirement has been selected for a given Component
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Requiring Study Plan'] off begin
		name: 'Requiring Study Plan',
		displayType: 'reference-multicombobox',
		order: 170,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.RequiringStudyPlanStudyPlanRequirement,
		optionEqualFunc: makeJoinEqualsFunc('requiringStudyPlanId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'requirementEntity',
			oppositeEntityName: 'studyPlanEntity',
			relationName: 'studyPlanRequirement',
			relationOppositeName: 'requiringStudyPlan',
			entity: () => Models.RequirementEntity,
			joinEntity: () => Models.RequiringStudyPlanStudyPlanRequirement,
			oppositeEntity: () => Models.StudyPlanEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Requiring Study Plan'] end
	})
	public requiringStudyPlans: Models.RequiringStudyPlanStudyPlanRequirement[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Unit Condition'] off begin
		name: 'Unit Conditions',
		displayType: 'reference-multicombobox',
		order: 180,
		referenceTypeFunc: () => Models.UnitConditionEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'unitConditions',
			oppositeEntity: () => Models.UnitConditionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Unit Condition'] end
	})
	public unitConditions: Models.UnitConditionEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IRequirementEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IRequirementEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.requirementType !== undefined) {
				this.requirementType = attributes.requirementType;
			}
			if (attributes.category !== undefined) {
				this.category = attributes.category;
			}
			if (attributes.displayName !== undefined) {
				this.displayName = attributes.displayName;
			}
			if (attributes.condition !== undefined) {
				this.condition = attributes.condition;
			}
			if (attributes.order !== undefined) {
				this.order = attributes.order;
			}
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.displayOnSidebar !== undefined) {
				this.displayOnSidebar = attributes.displayOnSidebar;
			}
			if (attributes.compulsory !== undefined) {
				this.compulsory = attributes.compulsory;
			}
			if (attributes.showDiscontinuedCourses !== undefined) {
				this.showDiscontinuedCourses = attributes.showDiscontinuedCourses;
			}
			if (attributes.programStructureId !== undefined) {
				this.programStructureId = attributes.programStructureId;
			}
			if (attributes.programStructure !== undefined) {
				if (attributes.programStructure === null) {
					this.programStructure = attributes.programStructure;
				} else if (attributes.programStructure instanceof Models.ProgramStructureEntity) {
					this.programStructure = attributes.programStructure;
					this.programStructureId = attributes.programStructure.id;
				} else {
					this.programStructure = new Models.ProgramStructureEntity(attributes.programStructure);
					this.programStructureId = this.programStructure.id;
				}
			}
			if (attributes.courseOfferings !== undefined && Array.isArray(attributes.courseOfferings)) {
				for (const model of attributes.courseOfferings) {
					if (model instanceof Models.CourseOfferingEntity) {
						this.courseOfferings.push(model);
					} else {
						this.courseOfferings.push(new Models.CourseOfferingEntity(model));
					}
				}
			}
			if (attributes.requirements !== undefined && Array.isArray(attributes.requirements)) {
				for (const model of attributes.requirements) {
					if (model instanceof Models.RequirementEntity) {
						this.requirements.push(model);
					} else {
						this.requirements.push(new Models.RequirementEntity(model));
					}
				}
			}
			if (attributes.parentRequirementId !== undefined) {
				this.parentRequirementId = attributes.parentRequirementId;
			}
			if (attributes.parentRequirement !== undefined) {
				if (attributes.parentRequirement === null) {
					this.parentRequirement = attributes.parentRequirement;
				} else if (attributes.parentRequirement instanceof Models.RequirementEntity) {
					this.parentRequirement = attributes.parentRequirement;
					this.parentRequirementId = attributes.parentRequirement.id;
				} else {
					this.parentRequirement = new Models.RequirementEntity(attributes.parentRequirement);
					this.parentRequirementId = this.parentRequirement.id;
				}
			}
			if (attributes.rootProgramStructureId !== undefined) {
				this.rootProgramStructureId = attributes.rootProgramStructureId;
			}
			if (attributes.rootProgramStructure !== undefined) {
				if (attributes.rootProgramStructure === null) {
					this.rootProgramStructure = attributes.rootProgramStructure;
				} else if (attributes.rootProgramStructure instanceof Models.ProgramStructureEntity) {
					this.rootProgramStructure = attributes.rootProgramStructure;
					this.rootProgramStructureId = attributes.rootProgramStructure.id;
				} else {
					this.rootProgramStructure = new Models.ProgramStructureEntity(attributes.rootProgramStructure);
					this.rootProgramStructureId = this.rootProgramStructure.id;
				}
			}
			if (attributes.selectedCourses !== undefined && Array.isArray(attributes.selectedCourses)) {
				for (const model of attributes.selectedCourses) {
					if (model instanceof Models.PlanSelectionEntity) {
						this.selectedCourses.push(model);
					} else {
						this.selectedCourses.push(new Models.PlanSelectionEntity(model));
					}
				}
			}
			if (attributes.requiringStudyPlans !== undefined && Array.isArray(attributes.requiringStudyPlans)) {
				for (const model of attributes.requiringStudyPlans) {
					if (model instanceof Models.RequiringStudyPlanStudyPlanRequirement) {
						this.requiringStudyPlans.push(model);
					} else {
						this.requiringStudyPlans.push(new Models.RequiringStudyPlanStudyPlanRequirement(model));
					}
				}
			}
			if (attributes.unitConditions !== undefined && Array.isArray(attributes.unitConditions)) {
				for (const model of attributes.unitConditions) {
					if (model instanceof Models.UnitConditionEntity) {
						this.unitConditions.push(model);
					} else {
						this.unitConditions.push(new Models.UnitConditionEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		requiringStudyPlans {
			${Models.RequiringStudyPlanStudyPlanRequirement.getAttributes().join('\n')}
			requiringStudyPlan {
				${Models.StudyPlanEntity.getAttributes().join('\n')}
			}
		}
		programStructure {
			${Models.ProgramStructureEntity.getAttributes().join('\n')}
		}
		courseOfferings {
			${Models.CourseOfferingEntity.getAttributes().join('\n')}
		}
		requirements {
			${Models.RequirementEntity.getAttributes().join('\n')}
		}
		parentRequirement {
			${Models.RequirementEntity.getAttributes().join('\n')}
		}
		rootProgramStructure {
			${Models.ProgramStructureEntity.getAttributes().join('\n')}
		}
		selectedCourses {
			${Models.PlanSelectionEntity.getAttributes().join('\n')}
		}
		unitConditions {
			${Models.UnitConditionEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			requiringStudyPlans: {},
			courseOfferings: {},
			requirements: {},
			selectedCourses: {},
			unitConditions: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'requirements',
							'requiringStudyPlans',
							'unitConditions',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(RequirementEntity.prototype, 'created');
CRUD(modifiedAttr)(RequirementEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
