/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import CurriculumSupportCurriculumSupportEntity from 'Models/Security/Acl/CurriculumSupportCurriculumSupportEntity';
import VisitorsCurriculumSupportEntity from 'Models/Security/Acl/VisitorsCurriculumSupportEntity';
import UserCurriculumSupportEntity from 'Models/Security/Acl/UserCurriculumSupportEntity';
import StudentAdvisorCurriculumSupportEntity from 'Models/Security/Acl/StudentAdvisorCurriculumSupportEntity';
import InstitutionAdminCurriculumSupportEntity from 'Models/Security/Acl/InstitutionAdminCurriculumSupportEntity';
import AdminCurriculumSupportEntity from 'Models/Security/Acl/AdminCurriculumSupportEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface ICurriculumSupportEntityAttributes extends IModelAttributes {
	email: string;
	userName: string;
	twoFactorEnabled: boolean;
	lastName: string;
	firstName: string;

	institutionId: string;
	institution: Models.InstitutionEntity | Models.IInstitutionEntityAttributes;
	studyPlans: Array<
		| Models.StudyPlanEntity
		| Models.IStudyPlanEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('CurriculumSupportEntity', 'Curriculum Support')
// % protected region % [Customise your entity metadata here] end
export default class CurriculumSupportEntity extends Model
	implements ICurriculumSupportEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new CurriculumSupportCurriculumSupportEntity(),
		new VisitorsCurriculumSupportEntity(),
		new UserCurriculumSupportEntity(),
		new StudentAdvisorCurriculumSupportEntity(),
		new InstitutionAdminCurriculumSupportEntity(),
		new AdminCurriculumSupportEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		'email',
		'userName',
		'twoFactorEnabled',
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Email'] off begin
	@Validators.Email()
	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		name: 'Email',
		displayType: 'displayfield',
		order: 10,
		createFieldType: 'textfield',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public email: string;
	// % protected region % [Modify props to the crud options here for attribute 'Email'] end

	@observable
	@attribute()
	// % protected region % [Modify props to the crud options here for attribute 'TwoFactorEnabled'] on begin
	@CRUD({
		name: 'TwoFactorEnabled',
		displayType: 'hidden',
		order: 20,
		createFieldType: 'hidden',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public twoFactorEnabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'TwoFactorEnabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'UserName'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'UserName',
		displayType: 'hidden',
	})
	public userName: string;
	// % protected region % [Modify props to the crud options here for attribute 'UserName'] end

	// % protected region % [Modify props to the crud options here for attribute 'Password'] off begin
	@Validators.Length(12, 128)
	@observable
	@CRUD({
		name: 'Password',
		displayType: 'hidden',
		order: 30,
		createFieldType: 'password',
	})
	public password: string;
	// % protected region % [Modify props to the crud options here for attribute 'Password'] end

	// % protected region % [Modify props to the crud options here for attribute 'Confirm Password'] off begin
	@Validators.Custom('Password Match', (e: string, target: CurriculumSupportEntity) => {
		return new Promise(resolve => resolve(target.password !== e ? 'Password fields do not match' : null));
	})
	@observable
	@CRUD({
		name: 'Confirm Password',
		displayType: 'hidden',
		order: 40,
		createFieldType: 'password',
	})
	public _confirmPassword: string;
	// % protected region % [Modify props to the crud options here for attribute 'Confirm Password'] end

	// % protected region % [Modify props to the crud options here for attribute 'Last name'] off begin
	@Validators.Required()
	@observable
	@attribute<CurriculumSupportEntity, string>()
	@CRUD({
		name: 'Last name',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public lastName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Last name'] end

	// % protected region % [Modify props to the crud options here for attribute 'First name'] off begin
	@Validators.Required()
	@observable
	@attribute<CurriculumSupportEntity, string>()
	@CRUD({
		name: 'First name',
		displayType: 'textfield',
		order: 60,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public firstName: string;
	// % protected region % [Modify props to the crud options here for attribute 'First name'] end

	@Validators.Required()
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Institution'] off begin
		name: 'Institution',
		displayType: 'reference-combobox',
		order: 70,
		referenceTypeFunc: () => Models.InstitutionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Institution'] end
	})
	public institutionId: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public institution: Models.InstitutionEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Study Plan'] off begin
		name: 'Study Plans',
		displayType: 'reference-multicombobox',
		order: 80,
		referenceTypeFunc: () => Models.StudyPlanEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'studyPlans',
			oppositeEntity: () => Models.StudyPlanEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Study Plan'] end
	})
	public studyPlans: Models.StudyPlanEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<ICurriculumSupportEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<ICurriculumSupportEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.email !== undefined) {
				this.email = attributes.email;
			}
			if (attributes.userName !== undefined) {
				this.userName = attributes.userName;
			}
			if (attributes.twoFactorEnabled !== undefined) {
				this.twoFactorEnabled = attributes.twoFactorEnabled;
			}
			if (attributes.lastName !== undefined) {
				this.lastName = attributes.lastName;
			}
			if (attributes.firstName !== undefined) {
				this.firstName = attributes.firstName;
			}
			if (attributes.institutionId !== undefined) {
				this.institutionId = attributes.institutionId;
			}
			if (attributes.institution !== undefined) {
				if (attributes.institution === null) {
					this.institution = attributes.institution;
				} else if (attributes.institution instanceof Models.InstitutionEntity) {
					this.institution = attributes.institution;
					this.institutionId = attributes.institution.id;
				} else {
					this.institution = new Models.InstitutionEntity(attributes.institution);
					this.institutionId = this.institution.id;
				}
			}
			if (attributes.studyPlans !== undefined && Array.isArray(attributes.studyPlans)) {
				for (const model of attributes.studyPlans) {
					if (model instanceof Models.StudyPlanEntity) {
						this.studyPlans.push(model);
					} else {
						this.studyPlans.push(new Models.StudyPlanEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		institution {
			${Models.InstitutionEntity.getAttributes().join('\n')}
			${Models.InstitutionEntity.getFiles().map(f => f.name).join('\n')}
		}
		studyPlans {
			${Models.StudyPlanEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			studyPlans: {},
		};

		if (formMode === 'create') {
			relationPath.password = {};

			if (this.password !== this._confirmPassword) {
				throw Error('Password fields do not match');
			}
		}
		return this.save(
			relationPath,
			{
				graphQlInputType: formMode === 'create'
					? `[${this.getModelName()}CreateInput]`
					: `[${this.getModelName()}Input]`,
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'studyPlans',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		if (!this.firstName && !this.lastName) {
			return this.email;
		}

		return `${this.firstName} ${this.lastName}`;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	public getType =  CurriculumSupportEntity;
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(CurriculumSupportEntity.prototype, 'created');
CRUD(modifiedAttr)(CurriculumSupportEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
